import React, { Component, ReactNode } from "react"
import { connect } from "react-redux"
import { fetchInstallation } from "../services/client/actions"
import IsThisYourAccount from "../pages/IsThisYourAccount/IsThisYourAccount"

import {
  GuardConfigProvider,
  GuardedRoute,
  GuardedRoutes,
  GuardMiddleware,
  GuardProvider,
  FromGuardRouteOptions,
} from "react-router-guarded-routes"

import {
  Home,
  Loading,
  NotFound,
  NotAuthorized,
  Maintenance,
  Admin,
  InsertRelative,
  Validator,
  Verify,
  Authentication,
} from "../pages"
import {
  GUARD_IS_ADMIN,
  GUARD_IS_LOGGED_IN,
  GUARD_IS_NOT_ADMIN,
  GUARD_IS_NOT_LOGGED_IN,
  GUARD_IS_VERIFIED,
  GUARD_IS_NOT_VERIFIED,
} from "../types/contants"
import {
  CHECK_PHONE,
  CHECK_LASTNAME,
  CGU_PAGE,
  IS_THIS_YOUR_ACCOUNT,
  HOME_PAGE,
  ADMIN_PAGE,
  NOT_AUTHORIZED_PAGE,
  WELCOME_PAGE,
  VERIFY_PAGE,
  LOBBY_PAGE,
  SURVEY_PAGE,
  INSERT_RELATIVE_PAGE,
  FINALE_PAGE,
  CHECK_PASSWORD,
  REGISTER_EMAIL,
  REGISTER_PERSONAL_DATA,
  VERIFY_PHONE,
  RETRIEVE_ACCOUNT,
  MAINTENANCE,
  SET_RELATIVE_PAGE,
  CURRENT_CONSULTATIONS_PAGE,
} from "./constants"
import SetRelativeGender from "pages/InsertRelative/setRelativeGender"
import { ClientStore } from "../types/types"
import Lobby from "../pages/Lobby/Lobby"
import "./App.scss"
import Finale from "../pages/Finale/Finale"
import config from "react-global-configuration"
import Survey from "../pages/Survey/Survey"
import CurrentConsultations from "pages/currentConsultations/CurrentConsultations"

interface RootProps {
  fetchInstallation: () => void
  success: boolean
  admin: boolean
  login: boolean
  loading: boolean
  verified: boolean
  error: string | number | null
}


class Root extends Component<RootProps> {
  constructor(props: RootProps) {
    super(props)
    this.props.fetchInstallation()
  }

  private requireGuards = async (
    to: any,
    from: FromGuardRouteOptions,
    next: any,
    guardValue: string
  ) => {
    switch (guardValue) {
      case GUARD_IS_ADMIN:
        this.props.admin ? next() : next(ADMIN_PAGE, { replace: true })
        break
      case GUARD_IS_LOGGED_IN:
        if (!this.props.admin) next(ADMIN_PAGE, { replace: true })
        else if (!this.props.login) next(LOBBY_PAGE, { replace: true })
        else next()
        break
      case GUARD_IS_VERIFIED:
        if (!this.props.admin) next(NOT_AUTHORIZED_PAGE, { replace: true })
        else if (!this.props.login) {
          next(LOBBY_PAGE, { replace: true })
        } else next()
        break
      case GUARD_IS_NOT_VERIFIED:
        if (!this.props.admin) next(ADMIN_PAGE, { replace: true })
        else if (!this.props.login) next(LOBBY_PAGE, { replace: true })
        else if (this.props.verified) next(SURVEY_PAGE, { replace: true })
        else next()
        break
      case GUARD_IS_NOT_ADMIN:
        !this.props.admin ? next() : next(HOME_PAGE, { replace: true })
        break
      case GUARD_IS_NOT_LOGGED_IN:
        if (!this.props.admin) next(ADMIN_PAGE, { replace: true })
        else if (this.props.login) next(SURVEY_PAGE, { replace: true })
        else next()
        break
      default:
        next()
        break
    }
  }

  private isNotAdminGuard: GuardMiddleware = (to, from, next) => {
    this.requireGuards(to, from, next, GUARD_IS_NOT_ADMIN)
  }

  private isAdminGuard: GuardMiddleware = (to, from, next) => {
    this.requireGuards(to, from, next, GUARD_IS_ADMIN)
  }

  private isVerifiedGuard: GuardMiddleware = (to, from, next) => {
    this.requireGuards(to, from, next, GUARD_IS_VERIFIED)
  }

  private isNotVerifiedGuard: GuardMiddleware = (to, from, next) => {
    this.requireGuards(to, from, next, GUARD_IS_NOT_VERIFIED)
  }

  private isLoggedInGuard: GuardMiddleware = (to, from, next) => {
    this.requireGuards(to, from, next, GUARD_IS_LOGGED_IN)
  }

  private isNotLoggedInGuard: GuardMiddleware = (to, from, next) => {
    this.requireGuards(to, from, next, GUARD_IS_NOT_LOGGED_IN)
  }
  state = { shouldRefresh: false }

  timerUpdate = () => {
    const refreshAppEveryInMs: number =
      config.get("refreshAppEveryInMs") || 3600000
    const id = setTimeout(
      () => {
        this.setState({ shouldRefresh: true })
      },
      refreshAppEveryInMs // [timeOutHour] hour(s) in milliseconds
    )
    return () => clearTimeout(id)
  }

  componentDidMount = () => {
    // on lance le timer à l'initialisation
    // objectif : toutes les [timeOutHour] heures, mettre shouldRefresh à true
    this.timerUpdate()
  }

  componentDidUpdate = (
    prevProps: any,
    prevState: { shouldRefresh: boolean }
  ) => {
    const triggerOn = [HOME_PAGE].includes(window.location.pathname)
    // Si shouldRefresh est true
    if (this.state.shouldRefresh && !prevState.shouldRefresh) {
      if (!this.props.login && triggerOn) {
        // Et que aucun patient n'est connecté, et que l'on
        //  n'est pas sur une page critique,
        // on peut rafraichir
        console.log(
          "%c --- Automatic reload | [ UGuardProviderpdate App ]",
          "background: #222; color: #bada55"
        )
        document.location.reload()
      } else {
        // Sinon, on relance un timer, en espérant que la prochaine fois,
        // aucun patient ne soit connecté pour rafraichîr
        this.setState({ shouldRefresh: false })
      }
    }
    // Si le timer s'est désactivé à l'update précédent, on lance le timer
    else if (!this.state.shouldRefresh && prevState.shouldRefresh) {
      this.timerUpdate()
    }
  }

  public render = (): ReactNode => {
    return this.props.loading ? (
      <Loading></Loading>
    ) : !this.props.success && window.location.pathname !== MAINTENANCE ? (
      <NotAuthorized />
    ) : (
      <GuardConfigProvider>
        <GuardProvider>
          <GuardedRoutes>
            <GuardedRoute path={MAINTENANCE} element={<Maintenance />} />
            <GuardedRoute
              key={WELCOME_PAGE}
              path={WELCOME_PAGE}
              element={<Home />}
              guards={[this.isAdminGuard]}
            />
            <GuardedRoute
              key={HOME_PAGE}
              path={HOME_PAGE}
              element={<Home />}
              guards={[this.isAdminGuard]}
            />
            <GuardedRoute
              key={"/"}
              path={"/"}
              element={<Home />}
              guards={[this.isAdminGuard]}
            />

            <GuardedRoute
              path={NOT_AUTHORIZED_PAGE}
              element={NotAuthorized}
            />
            {
              [
                CHECK_PHONE,
                CHECK_PASSWORD,
                CHECK_LASTNAME,
                REGISTER_EMAIL,
                REGISTER_PERSONAL_DATA,
                VERIFY_PHONE,
              ].map((path) => (
                <GuardedRoute
                  key={path}
                  path={path}
                  element={<Validator />}
                  guards={[this.isNotLoggedInGuard]}
                />
              ))
            }
            <GuardedRoute
              path={IS_THIS_YOUR_ACCOUNT}
              element={<IsThisYourAccount />}
              guards={[this.isLoggedInGuard]}
            />
            <GuardedRoute
              path={SURVEY_PAGE}
              element={<Survey />}
              guards={[this.isVerifiedGuard]}
            />
            <GuardedRoute
              path={CURRENT_CONSULTATIONS_PAGE}
              element={<CurrentConsultations />}
              guards={[this.isVerifiedGuard]}
            />
            <GuardedRoute
              path={RETRIEVE_ACCOUNT}
              element={<Authentication />}
              guards={[this.isNotLoggedInGuard]}
            />

            <GuardedRoute
              path={FINALE_PAGE}
              guards={[this.isVerifiedGuard]}
              element={<Finale />}
            />
            <GuardedRoute
              path={ADMIN_PAGE}
              element={<Admin />}
              guards={[this.isNotAdminGuard]}
            />

            <GuardedRoute
              path={LOBBY_PAGE}
              element={<Lobby />}
              guards={[this.isNotLoggedInGuard]}
            />
            <GuardedRoute
              path={INSERT_RELATIVE_PAGE}
              element={<InsertRelative />}
              guards={[this.isVerifiedGuard]}
            />
            <GuardedRoute
              path={SET_RELATIVE_PAGE}
              element={<SetRelativeGender />}
              guards={[this.isVerifiedGuard]}
            />
            <GuardedRoute
              path={VERIFY_PAGE}
              element={<Verify />}
              guards={[this.isNotVerifiedGuard]}
            />
            <GuardedRoute path="*" element={NotFound} />
          </GuardedRoutes>
        </GuardProvider>
      </GuardConfigProvider>
    )
  }
}

export const mapStateToProps = ({
  client,
}: {
  client: ClientStore
}): Partial<RootProps> => {
  return {
    success: client.success,
    loading: client.loading,
    admin: !!client.clinic,
    login: !!client.patient,
    verified: client.patient?.is_verified ? true : false,
  }
}

const mapDispatchToProps = (dispatch: Function): Partial<RootProps> => {
  return {
    fetchInstallation: () => dispatch(fetchInstallation()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Root as any)
