import React, { useEffect } from "react"
import styles from "./../Errors.module.scss"
import { Layout } from "antd"
import { HOME_PAGE } from "../../../core/constants"
import WorkInProgressImg from "../../../assets/images/work_in_progress.svg"
import config from "react-global-configuration"
import { languages } from "lib/languages"

const Maintenance: React.FC<any> = (props: { history: any }) => {
  const maintenancePingIntervalInS =
    config.get("maintenancePingIntervalInS") || 60
  useEffect(() => {
    const pingServerStatus = () =>
      fetch("/api/ping/isAlive").then((response) => {
        if (response.status !== 503) {
          window.location.href = HOME_PAGE
          clearInterval(intervalPingServerId)
        }
      }).catch((error) => {
        console.error(error)
      })
    const intervalPingServerId = setInterval(
      pingServerStatus,
      1000 * maintenancePingIntervalInS
    )
    return () => clearInterval(intervalPingServerId);
  }, [])
  return (
    <Layout
      style={{
        height: "100vh",
        backgroundColor: "#1b4f63",
        cursor: "pointer",
        textAlign: "center",
      }}
    >
      <div className={styles.Maintenance} data-testid="Home">
        <div style={{ marginBottom: "100px" }}>
          <img
            className={styles.WipLogo}
            src={WorkInProgressImg}
            alt="Logo de Medadom"
          />
          <h1 className={styles.MaintenanceTitle}> {languages.maintenanceInProgress}</h1>
        </div>
        <h2 className={styles.MaintenanceSubTitle}>
          {languages.tryAgainLater}
        </h2>
      </div>
    </Layout>
  )
}

export default Maintenance
